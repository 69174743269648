<template>
    <div v-if="topicType == 5">
        
      <b-card v-for="(item, index) in topicData" style="border-radius: 20px;" :class="$store.state.appConfig.layout.skin == 'dark' ? 'custom-dark-card p-2': 'p-2'" >
        <h3 class="mb-1.25 font-weight-bolder">Homework</h3>
        
        <div 
            :class="
                $store.state.appConfig.layout.skin == 'dark' ? 'w-100 dark-card' : 'w-100'
            " 
          >
            <span v-html="item.text"></span>
          </div>
    </b-card>
    </div>
</template>
  
<script>
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { mapGetters } from "vuex";
    import VueApexCharts from 'vue-apexcharts';
    import vSelect from "vue-select";
    import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
    import { required, email, between } from '@validations'
    import 'quill/dist/quill.core.css'
    import { quillEditor } from 'vue-quill-editor'
    import {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BAvatar,
    VBTooltip,
    BLink,
    BSpinner,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BForm,
    } from "bootstrap-vue";
    extend('max_value', {
        validate(value, { max }) {
            return value <= max;
        },
        params: ['max'],
        message: 'The {_field_} must be {max} or less.',
    });
    export default {
        props: [
            'topicData',
            'topicType'
        ],
        name: "topics",
        components: {
            BTable,
            BButton,
            BFormCheckbox,
            BCard,
            BRow,
            BCol,
            BBadge,
            BAvatar,
            BCardText,
            BLink,
            BSpinner,
            BPagination,
            ToastificationContent,
            vSelect,
            VueApexCharts,
            BInputGroup,
            BFormInput,
            BFormTextarea,
            BFormFile,
            BForm,
            quillEditor,
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
            "b-tooltip": VBTooltip,
        },
        computed: {
            ...mapGetters({
            
            }),
        },
        
        data() {
            return {
                grade: [],
                comment: [],
                bubbleOption: {
                    modules: {
                    toolbar: false,
                    },
                    theme: 'bubble',
                },
            };
        },
        methods: {
            submit(answer_id,index){
                const payload = {
                    query:{ 
                        model_id: answer_id,
                        model_type: this.topicType == 1 ? 'Answer' : this.topicType == 2 ? 'Answer' : 'OQAnswer',
                        comment: this.comment[index],
                        grade: this.grade[index],
                    }
                }
                this.$store.dispatch('students/storeFeedback', payload).then(_=>{
                    this.trigger = false
                    this.$emit('refresh')
                    this.$swal({
                        icon: 'success',
                        title: 'Saved Successfully!',
                        confirmButtonColor: '#E84185',
                    })
                    this.trigger = true

                }).catch(error => {
                    if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.message}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                    }
                });
            },
            init(){
                this.trigger = false
            }
        },
        mounted(){
            this.init() 
        }
    };
</script>
<style lang="scss" scoped>
    .custom-input-grade {
        background-color: #f8f9fa; /* Gray background */
        width: 150px; /* Custom width */
        padding: 0.5rem; /* Optional: Adjust padding */
    }
    .custom-dark-card{
        border: 1px #3A3A3A solid;
        background-color: transparent;
    }
</style>