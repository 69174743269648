<template>
    <div v-if="trigger && topicType == 3">
        
      <b-card v-for="(item, index) in questions" style="border-radius: 20px;" :class="$store.state.appConfig.layout.skin == 'dark' ? 'custom-dark-card p-2': 'p-2'" >
        <h3 class="mb-1.25 font-weight-bolder">Homework time</h3>
        <p style="font-size: 12px" >{{ item.open_questions }}</p>
        <h3 class="mt-2 mb-1.25 font-weight-bolder">Student response</h3>
        <p style="font-size: 12px;" v-if="item.answers.length > 0">{{ item.answers[0].answer }}</p>
        <div v-if="authUser().user.type != 2">
            <h3 class="mt-2 mb-1.25 font-weight-bolder">LLM feedback</h3>
            <p style="font-size: 12px;" >Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab ipsum quaerat labore provident, animi iure maiores soluta obcaecati modi impedit velit? Necessitatibus dolorum a nesciunt ipsa non totam nostrum odit!</p>
        </div>
        <div v-else>
            <h3 class="mt-2 mb-1.25 font-weight-bolder">Feedback</h3>
            <p style="font-size: 12px;"> {{ item.answers[0].feedback.length > 0 ? item.answers[0].feedback[0].comment : ''}} </p>
        </div>
        <div>
            <h3 class="mt-2 mb-1.25 font-weight-bolder">Grade</h3>
            <p style="font-size: 12px;"> {{ item.answers[0].feedback.length > 0 ? item.answers[0].feedback[0].grade : ''}} </p>

        </div>
        <div v-if="authUser().user.type != 2" >
            <h5 class="mt-2 font-weight-bolder">Add score</h5>
            <div class="d-flex">
                <div style="width: 100px;"> 
                    <validation-provider
                        #default="{ errors }"
                        name="grade"
                        vid="grade"
                        rules="required"
                    >
                            <b-input-group
                                class="input-group-merge mr-1"
                            >
                                <b-form-input
                                    :disabled="item.answers.length == 0"
                                    :class="item.answers.length == 0 ? 'cursor-disabled' : ''"
                                    v-model="grade[index]"
                                    placeholder="0"
                                    max="100"
                                    type="number"
                                    id="grade"
                                    style="background-color: #f6f6f6;"
                                />
                                </b-input-group>
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                    </validation-provider>
                </div>
                <span style="margin: 10px 6px;"><h5>/ 100</h5></span>
            </div>
            <h5 class="mt-2 font-weight-bolder">Comment</h5>
            <!-- <validation-provider
                #default="{ errors }"
                name="comment"
                vid="comment"
                rules="required"
                > -->
            
                    <b-input-group
                        class="input-group-merge mr-1"
                    >
                        <b-form-textarea
                            :disabled="item.answers.length == 0"
                            :class="item.answers.length == 0 ? 'cursor-disabled' : ''"
                            id="textarea-default"
                            v-model="comment[index]"
                            placeholder=""
                            style="background-color: #f6f6f6;"
                        ></b-form-textarea>
                    </b-input-group>
                    <!-- <small class="text-danger float-left">{{ errors[0] }}</small> -->
            <!-- </validation-provider> -->
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    :class="item.answers.length == 0 ? 'cursor-disabled rounded-pill mt-3 pr-4 pl-4' : 'rounded-pill mt-3 pr-4 pl-4'"
                    :disabled="item.answers.length == 0"
                    type="submit"
                    @click="submit(item.answers[0].id, index)"
                >
                    save
                </b-button>
            </div>
        </div>
    </b-card>
    </div>
</template>
  
<script>
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { mapGetters } from "vuex";
    import VueApexCharts from 'vue-apexcharts';
    import vSelect from "vue-select";
    import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
    import { required, email, between } from '@validations'
    import {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BAvatar,
    VBTooltip,
    BLink,
    BSpinner,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BForm,
    } from "bootstrap-vue";
    extend('max_value', {
        validate(value, { max }) {
            return value <= max;
        },
        params: ['max'],
        message: 'The {_field_} must be {max} or less.',
    });
    export default {
        props: [
            'questions',
            'topicType'
        ],
        name: "topics",
        components: {
            BTable,
            BButton,
            BFormCheckbox,
            BCard,
            BRow,
            BCol,
            BBadge,
            BAvatar,
            BCardText,
            BLink,
            BSpinner,
            BPagination,
            ToastificationContent,
            vSelect,
            VueApexCharts,
            BInputGroup,
            BFormInput,
            BFormTextarea,
            BFormFile,
            BForm,
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
            "b-tooltip": VBTooltip,
        },
        computed: {
            ...mapGetters({
            
            }),
        },
        
        data() {
            return {
                grade: [],
                comment: [],
                trigger: true
            };
        },
        methods: {
            submit(answer_id,index){
                const payload = {
                    query:{ 
                        model_id: answer_id,
                        model_type: this.topicType == 1 ? 'Answer' : this.topicType == 2 ? 'Answer' : 'OQAnswer',
                        comment: this.comment[index],
                        grade: this.grade[index],
                    }
                }
                this.$store.dispatch('students/storeFeedback', payload).then(_=>{
                    this.trigger = false
                    this.$emit('refresh')
                    this.$swal({
                        icon: 'success',
                        title: 'Saved Successfully!',
                        confirmButtonColor: '#E84185',
                    })
                    this.trigger = true

                }).catch(error => {
                    if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.message}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                    }
                });
            },
            init(){
                this.trigger = false
                this.questions.forEach((el,index) => {
                    if(el.answers.length > 0 && el.answers[0].feedback.length > 0){
                        this.grade[index] = el.answers[0].feedback[0].grade ? el.answers[0].feedback[0].grade : null 
                        this.comment[index] = el.answers[0].feedback[0].comment ? el.answers[0].feedback[0].comment : null 
                    }
                });
                this.trigger = true
            }
        },
        mounted(){
            this.init() 
        }
    };
</script>
<style lang="scss" scoped>
    .custom-input-grade {
        background-color: #f8f9fa; /* Gray background */
        width: 150px; /* Custom width */
        padding: 0.5rem; /* Optional: Adjust padding */
    }
    .custom-dark-card{
        border: 1px #3A3A3A solid;
        background-color: transparent;
    }
</style>